import request  from '../request'
import { BASE_URL } from '../../common/keys';

const psp = {
  // 获取当前项目所有车位信息
  getParkingSpaceList () {
    return request.get(`${BASE_URL}/parkingspace/getParkingSpaceList`);
  },
  // 获取车位智能推荐类型数据
  getIntelligentRecommendation() {
    return request.get(`${BASE_URL}/parkingspace/getIntelligentRecommendation`);
  },
  // 获取停车车型列表
  getParkingVehicleList() {
    return request.get(`${BASE_URL}/parkingspace/getParkingVehicleList`);
  },
  // 获取涂鸦商品列表信息
  getGraffitiGoods() {
    return request.get(`${BASE_URL}/goods/getGraffitiGoods`);
  },
  // 查询当前用户的车位收藏记录
  getUserFollowRecord() {
    return request.get(`${BASE_URL}/parkingspace/getUserFollowRecord`);
  },
  // 获取对比车位记录
  getParkingSpaceContrast() {
    return request.get(`${BASE_URL}/parkingspace/getParkingSpaceContrast`);
  },
  // 获取当前车位信息
  getParkingSpaceInfo(id) {
    return request.get(`${BASE_URL}/parkingspace/getParkingSpaceInfo?id=${id}`);
  },
  // 添加车位对比记录
  addParkingSpaceContrast(id) {
    return request.get(`${BASE_URL}/parkingspace/addParkingSpaceContrast?id=${id}`);
  },
  // 收藏停车位
  addParkingCollection(id) {
    return request.get(`${BASE_URL}/parkingspace/addParkingCollection?id=${id}`);
  },
  // 商品加入购物车
  addOrderItem(data) {
    return request.post(`${BASE_URL}/order/addOrderItem`,data);
  },
  // 移除车位对比记录
  removeParkingSpaceContrast(id) {
    return request.get(`${BASE_URL}/parkingspace/removeParkingSpaceContrast?id=${id}`);
  },
  // 取消收藏停车位
  delParkingCollection(id) {
    return request.get(`${BASE_URL}/parkingspace/delParkingCollection?id=${id}`);
  },
  // 新增车位查看记录
  addViewLog(id) {
    return request.get(`${BASE_URL}/parkingspace/addViewLog?id=${id}`);
  },
  // 批量添加车位对比记录
  addParkingSpaceContrastList(ids) {
    return request.get(`${BASE_URL}/parkingspace/addParkingSpaceContrastList?ids=${ids.join(',')}`);
  },
  // 批量商品加入购物车
  addOrderItems(data) {
    return request.post(`${BASE_URL}/order/addOrderItems`,data);
  },
  // 获取车位类型信息
  getParkingSpaceTypeInfo(id) {
    return request.get(`${BASE_URL}/parkingspace/getParkingSpaceTypeInfo?id=${id}`);
  },
  // 名词解释
  getNounExplanation(optionId,type) {
    return request.get(`${BASE_URL}/nounExplanation/getDetail?optionId=${optionId}&type=${type}`);
  },
  // 获取楼层标高
  getBuildingHeights() {
    return request.get(`${BASE_URL}/project/getBuildingHeights`);
  },
}

export default psp;
