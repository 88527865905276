import { Toast,Dialog } from 'vant';
import api from 'network/api';
import Tools from "@/assets/js/tools";
import {COMMON_DES_KEY,PAY_CACHE_DATA,TEMP_ORDER} from 'common/keys'
import utils from './utils';

let _isWechatOs = false;
let projectInfo = undefined;
let cache = {};

const defaultConfirmTime = 20;
let confirmTime = defaultConfirmTime;
let confirmInterval = undefined;


const isWechatOs = () => {
    var ua = navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
    } else {
        return false;
    }
}


_isWechatOs = isWechatOs();


const jumpH5Pay = (data) => {
    let redirectUri = data + '&redirect_url=' + getRedirectUri();
    cache.redirectUri = redirectUri;
    Tools.setLocal(COMMON_DES_KEY,PAY_CACHE_DATA,cache);
    location.href = redirectUri;
}


const jumpWechat = async () => {
    if(projectInfo === undefined) {
        projectInfo = await api.project.getInfo();
    }
    let redirectUri = getRedirectUri();
    cache.redirectUri = redirectUri;
    Tools.setLocal(COMMON_DES_KEY,PAY_CACHE_DATA,cache);
    let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${projectInfo.appid}&redirect_uri=${redirectUri}&response_type=code&scope=snsapi_base&state=${new Date().getTime()}#wechat_redirect`
    location.href = url;
}

const getRedirectUri = function() {
    let url = location.href;
    let code = utils.getQueryVariable('code');
    let state = utils.getQueryVariable('state');
    if(code !== null && state !== null) {
        url = url.replace(`code=${code}&state=${state}`,'');
    }
    return encodeURIComponent(url);
}

const dialogConfirm = () => {
    let title = confirmTime <= 0 ? '支付确认' : `支付确认(${confirmTime})`
    let disableClk = confirmTime > 0;
    Dialog.confirm({
        title: title,
        confirmButtonText: '已经完成支付',
        closeOnPopstate: false,
        confirmButtonDisabled: disableClk,
        cancelButtonDisabled: disableClk,
        message:
          '请在微信内完成支付，如果您已支付成功，请点击“已完成支付”按钮',
    }).finally(() => {
        finish();
    });
}


const finish = () => {
    Tools.setLocal(COMMON_DES_KEY,PAY_CACHE_DATA,null);
    if(cache.isRePay || cache.type !== 1) {
        Tools.setLocal(COMMON_DES_KEY, TEMP_ORDER, null);
        location.href = "/#/order-detail-paid?backStep=2&id=" + cache.id;
    } else {
        api.order.getOrderInfo({orderId:cache.id}).then((info) => {
            if (info.payStatus == 0 || info.payStatus == 3) {
                location.href = "/#/order-detail-paid?backStep=2&id=" + info.id;
            } else if (info.payStatus == 1 || info.payStatus == 2 || info.payStatus == 4 || info.payStatus == 5) {
                location.href = "/#/payment-success?backStep=2&id=" + info.id;
            }
        });
    }
}

const saveOrder = (type,payType,data) => {
    cache.type  = type;
    cache.payType  = payType;
    cache.data  = data;
    cache.isRePay = false;
    if(_isWechatOs) {
        cache.tradeType = 'JSAPI'
        if(typeof WeixinJSBridge === 'undefined') {
            Toast('当前环境不支持微信支付');
        } else {
            jumpWechat();
        }
    } else {
        cache.tradeType = 'MWEB';
        _saveOrder(type,payType,cache.tradeType,null,data);
    }
}
const _toPay = (tradeType,data) => {
    confirmTime = defaultConfirmTime;
    dialogConfirm();
    confirmInterval = setInterval(() => {
        confirmTime--;
        dialogConfirm();
        if(confirmInterval !== undefined && confirmTime <= 0) {
            clearInterval(confirmInterval);
            confirmInterval = undefined;
        }
    }, 1000);
    
    if(tradeType === 'MWEB') {
        jumpH5Pay(data);
    } else {
        // eslint-disable-next-line no-undef
        WeixinJSBridge.invoke('getBrandWCPayRequest', JSON.parse(data),
        function() {
            // if (res.err_msg == "get_brand_wcpay_request:ok") {
            //     // 使用以上方式判断前端返回,微信团队郑重提示：
            //     //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            // }
            if(confirmInterval !== undefined) {
                confirmTime = 0;
                dialogConfirm();
                clearInterval(confirmInterval);
                confirmInterval = undefined;
            }
        });
    }
}

const rePay = (payType,data) => {
    cache.isRePay = true;
    cache.payType  = payType;
    cache.data  = data;
    if(_isWechatOs) {
        cache.tradeType = 'JSAPI'
        if(typeof WeixinJSBridge === 'undefined') {
            Toast('当前环境不支持微信支付');
        } else {
            jumpWechat();
        }
    } else {
        cache.tradeType = 'MWEB';
        _rePay(payType,cache.tradeType,null,data);
    }
}

const _rePay = (payType,tradeType,code,data = {}) => {
    api.order.getWxPayUrl({
        ...data,
        payType: payType,
        tradeType: tradeType,
        code: code,
    }).then(result => {
        _onReqEnd(tradeType,result);
    })
};

const  _onReqEnd = (tradeType,result) => {
    cache.id = result.id;
    if(result.finished) {
        finish();
    } else {
        _toPay(tradeType,result.data)
    }
}

const _saveOrder = (type,payType,tradeType,code,data = {}) => {
    if(type === 1) {
        api.order.saveIntentionOrder({
            ...data,
            payType: payType,
            tradeType: tradeType,
            code: code,
        }).then(result => {
            _onReqEnd(tradeType,result);
        })
    } else {
        api.order.saveOrder({
            ...data,
            payType: payType,
            tradeType: tradeType,
            code: code
        }).then(result => {
            _onReqEnd(tradeType,result);
        })
    }
}


const dispatchEvent = (code) => {
    cache = Tools.getLocal(COMMON_DES_KEY,PAY_CACHE_DATA);
    // let redirectUri = getRedirectUri();
    // if(cache.redirectUri !== redirectUri) {
    //     Tools.setLocal(COMMON_DES_KEY,PAY_CACHE_DATA,null);
    //     return;
    // }
    if(null === code || undefined === code || '' === code.trim()) {
        confirmTime = 0;
        dialogConfirm();
        return;
    }
    if(cache.isRePay) {
        _rePay(cache.payType,cache.tradeType,code,cache.data);
    } else {
        _saveOrder(cache.type,cache.payType,cache.tradeType,code,cache.data);
    }
}

export default {
    saveOrder,
    dispatchEvent,
    rePay
}
